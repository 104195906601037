import React, { useState, useEffect } from 'react'
import './reset.css'
import './App.css'
import firebase from './lib/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface IUser {
  id?: string
  photoURL?: string
  screenName?: string
  accessToken?: string
  secret?: string
  registerTweet?: boolean
}

const db = firebase.firestore()

function App() {
  const [firebaseUser, setFirebaseUser] = useState<null | firebase.User>(null)
  const [user, setUser] = useState<null | IUser>(null)
  const [checked, setChecked] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((u) => {
      setFirebaseUser(u)
    })
  }, [user])

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then(async (result) => {
        setLoading(false)

        if (result.credential) {
          const twchecked = localStorage.getItem('twchecked')
          localStorage.removeItem('twchecked')

          const credential = result.credential as firebase.auth.OAuthCredential
          const user: IUser = {
            id: result.user!.uid,
            photoURL: result.user!.photoURL!,
            screenName: result.additionalUserInfo!.username!,
            accessToken: credential.accessToken,
            secret: credential.secret,
          }

          if (twchecked) {
            user.registerTweet = true
          }

          const userDoc = await db.collection('users').doc(user.id).get()

          if (twchecked && !userDoc.exists) {
            user.registerTweet = true
          }

          await userDoc.ref.set(user)
          setUser(user)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  function login() {
    const provider = new firebase.auth.TwitterAuthProvider()
    provider.setCustomParameters({
      force_login: 'true',
    })
    if (checked) {
      localStorage.setItem('twchecked', 'true')
    }
    firebase.auth().signInWithRedirect(provider)
  }

  function logout() {
    firebase.auth().signOut()
  }

  return (
    <div className="App">
      <h1>保守ったー</h1>
      <div className="content">
        {loading && (
          <div>
            <FontAwesomeIcon icon={faSpinner} spin /> loading...
          </div>
        )}
        {!loading && !firebaseUser && (
          <div>
            <button className="twitter-button" style={{ background: 'gray' }}>
              <FontAwesomeIcon icon={faTwitter} />
              登録する
            </button>

            <div style={{ marginTop: 10, textDecoration: 'line-through' }}>
              <label>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                登録したことをツイートする
              </label>
            </div>
          </div>
        )}
        {!loading && firebaseUser && (
          <>
            <div>登録済みです! </div>
            <button
              onClick={logout}
              style={{
                border: '1px solid #333',
                marginTop: 10,
                cursor: 'pointer',
              }}
            >
              ログアウトする
            </button>
          </>
        )}
      </div>

      <div className="caption">
        <div
          style={{
            background: '#fdc6c6',
            border: '2px solid #ff0000',
            padding: '8px 12px',
            borderRadius: '4px',
            textAlign: 'left',
          }}
        >
          <div>
            <b>2023年2月3日</b>
          </div>
          Twitter
          APIの有料化、及び自動投稿を行っているアカウントに凍結リスクが発生したため、サービスの提供を終了します。すでに登録済みのアカウントに関してましても、今後は自動投稿を行わなくなります。
          永らくのご利用ありがとうございました。
          <ul>
            <li>
              Twitterで“大量凍結祭り”発生中　「マシュマロ」などの自動投稿ツールが原因？
              <a
                href="https://www.buzzfeed.com/jp/hayatoikeya/twitter-touketsu"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.buzzfeed.com/jp/hayatoikeya/twitter-touketsu
              </a>
            </li>
            <li>
              Twitterで多数のアカウントが凍結　「凍結祭り」「Twitter氷河期」などトレンド入りする事態に（1/2
              ページ） - ねとらぼ
              <a
                href="https://nlab.itmedia.co.jp/nl/articles/2302/03/news093.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://nlab.itmedia.co.jp/nl/articles/2302/03/news093.html
              </a>
            </li>
            <li>
              マスクCEO、Twitterの無料API終了は「悪質なbotの排除」と説明　有料APIは月100ドル程度に
              - ITmedia NEWS
              <a
                href="https://www.itmedia.co.jp/news/articles/2302/03/news110.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.itmedia.co.jp/news/articles/2302/03/news110.html
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="caption" style={{ textDecoration: 'line-through' }}>
        アクティブでないTwitterアカウントは削除されてしまうという話があります。このWebサービスは、登録した
        Twitter アカウントに30日間ツイートがない場合、自動的に
        <a
          href="https://2ch.me/vikipedia/%E4%BF%9D%E5%AE%88"
          target="_blank"
          rel="noopener noreferrer"
        >
          保守
        </a>
        ツイートをしてアクティブでないとみなされる可能性を減らします。
      </div>
      <div className="description">
        <h3>免責事項</h3>
        <ul>
          <li>
            Twitterの定義する「アクティブでないTwitterアカウント」の詳細は不明です。このサービスに登録したから事によって、アカウントが削除されない事を保証することはできません。
          </li>
          <li>サービスの仕様上、ツイートを行う場合があります。</li>
          <li>予告なくサービスの仕様を変更する場合があります。</li>
        </ul>
        <h3>退会について</h3>
        <p>
          Twitterの連携アプリからアクセス権限を削除してください。{' '}
          <a
            href="https://twitter.com/settings/applications"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://twitter.com/settings/applications
          </a>
        </p>
        <h3>関連リンク</h3>
        <ul>
          <li>
            Twitter - 運営の痕跡がないアカウントに関するポリシー
            <a
              href="https://help.twitter.com/ja/rules-and-policies/inactive-twitter-accounts"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://help.twitter.com/ja/rules-and-policies/inactive-twitter-accounts
            </a>
          </li>
          <li>
            Twitter、休眠アカウント削除へ　対象アカウントに12月11日までにログインするよう警告
            - ITmedia NEWS
            <a
              href="https://www.itmedia.co.jp/news/articles/1911/27/news069.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.itmedia.co.jp/news/articles/1911/27/news069.html
            </a>
          </li>
          <li>
            Twitter prepares for huge cull of inactive users - BBC News
            <a
              href="https://www.bbc.com/news/technology-50567751"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.bbc.com/news/technology-50567751
            </a>
          </li>
        </ul>
      </div>

      <div className="copy">
        著:{' '}
        <a
          href="https://twitter.com/9m"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://twitter.com/9m
        </a>
      </div>
    </div>
  )
}

export default App
