import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCMLbnthyfxHXyYHxfARFWuoDs1VA2Z64k',
  authDomain: 'hosyu-twitter.firebaseapp.com',
  databaseURL: 'https://hosyu-twitter.firebaseio.com',
  projectId: 'hosyu-twitter',
  storageBucket: 'hosyu-twitter.appspot.com',
  messagingSenderId: '27099985376',
  appId: '1:27099985376:web:c14268d8cbbf99133a6785',
  measurementId: 'G-T3J1T0N8YX'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export default firebase
